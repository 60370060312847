import { ActionsWrapper } from './Actions'
import { Bottom } from './Bottom'
import { Box, CardContent, Typography } from '@mui/material'
import clsx from 'clsx'
import horizontalStyles from './Horizontal.module.scss'
import { IAdornments, IBaseLayout, ProductImgSize } from './types'
import { ProductImage } from './ProductImage'
import React from 'react'
import styles from './TabletSmall.module.scss'
import { OrderItemForPdfProps } from '../makeOrderItem'
import { ICartItemForPdfProps } from '../../product-card/makeCartItem'

export interface IHorizontalLayoutProps extends IBaseLayout, IAdornments {
  className?: string
  productImageSize?: ProductImgSize
  mobile?: boolean
  mapValues: null | ((aditionalValues: JSX.Element[]) => JSX.Element)
  bottomRightAdornments: React.ReactNode | null
  shippingValue?: { shippedAmount: number; orderedAmount: number; unit: string }
}

export const TabletSmall: React.FC<
  IHorizontalLayoutProps & ICartItemForPdfProps & OrderItemForPdfProps
> = (props) => {
  const {
    className,
    productImageSize = '128',
    imageElement,
    titleElement,
    propertiesElements,
    labels,
    stockElements: statusesElement,
    counter,
    mapValues,
    topAdornments,
    leftAdornments,
    bottomAdornments,
    bottomExtension,
    bottomRightAdornments,
    actionsElements,
    shippingValue,
    deadProduct,
    hideActionsElements,
    hideImage,
  } = props

  const additionalValues: JSX.Element[] = []
  let headerSlot: React.ReactChild | React.ReactChild[] = titleElement

  let contentSlots: React.ReactChild[] = [
    <div
      key="tabletSmall-properties"
      className={clsx(horizontalStyles.horizontalProperties, {
        [horizontalStyles.horizontalPropertiesDeadProduct]: deadProduct,
      })}
    >
      {propertiesElements}
    </div>,
  ]

  if (topAdornments) {
    headerSlot = topAdornments
    contentSlots = [titleElement, ...contentSlots]
  }

  !deadProduct &&
    contentSlots.push(
      <div className={styles.stocksAndLabelWrapper}>
        {statusesElement ?? null}
        {labels ?? null}
      </div>
    )

  let itemShippingInfo
  if (shippingValue) {
    itemShippingInfo = (
      <Box className={horizontalStyles.shippingValue}>
        <Typography variant={'boldText'}>
          {`${shippingValue.shippedAmount} / ${shippingValue.orderedAmount} ${shippingValue.unit}`}
        </Typography>
        <Typography variant={'smallText'} color={'text.secondary'}>
          Geliefert
        </Typography>
      </Box>
    )
  }

  return (
    <CardContent
      className={clsx(horizontalStyles.layoutWrapper, className, {
        [horizontalStyles.positionedRelative]: deadProduct,
      })}
    >
      <div className={styles.layout}>
        <div className={styles.imageAndContentWrapper}>
          {leftAdornments}
          {!hideImage && <ProductImage size={productImageSize}>{imageElement}</ProductImage>}
          <div
            className={clsx(horizontalStyles.content, {
              [horizontalStyles.contentDeadProduct]: deadProduct,
            })}
          >
            <div className={horizontalStyles.headerWrap}>
              {!hideActionsElements && (
                <ActionsWrapper layout="horizontal">{actionsElements}</ActionsWrapper>
              )}
              <div className={horizontalStyles.header}>{headerSlot}</div>
            </div>
            {contentSlots}
            {bottomAdornments}
            {bottomExtension}
          </div>
        </div>
        <div className={styles.valuesWrapper}>
          <div
            className={clsx(horizontalStyles.footer, {
              [horizontalStyles.singleColumn]: !(counter || bottomRightAdornments),
              [horizontalStyles.shippingInfo]: shippingValue,
            })}
          >
            {shippingValue && itemShippingInfo}
            {mapValues && mapValues(additionalValues)}
            {(counter || bottomRightAdornments) && (
              <Bottom className={horizontalStyles.horizontalBottomElement}>
                {counter}
                {bottomRightAdornments}
              </Bottom>
            )}
          </div>
        </div>
      </div>
    </CardContent>
  )
}
