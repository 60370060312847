import { Box, CardContent, Typography } from '@mui/material'
import { LightGrayDivider } from '../../light-gray-divider/LightGrayDivider'
import { Bottom } from './Bottom'
import { ProductImage } from './ProductImage'
import { IAdornments, IBaseLayout } from './types'
import styles from './Mobile.module.scss'
import clsx from 'clsx'
import React from 'react'

interface IMobileLayout extends IBaseLayout, IAdornments {
  values: JSX.Element | null
  imageSize?: '128' | '223'
  shippingValue?: { shippedAmount: number; orderedAmount: number; unit: string }
}

export const Mobile: React.FC<IMobileLayout> = (props) => {
  const {
    values,
    actionsElements,
    titleElement,
    imageElement,
    labels,
    topAdornments,
    propertiesElements,
    stockElements: statusesElement,
    counter,
    leftAdornments,
    bottomAdornments,
    bottomExtension,
    shippingValue,
    imageSize = '128',
    deadProduct,
    hideActionsElements,
    hideImage,
  } = props

  const titlePartOfHeader = parseInt(imageSize) <= 128

  let itemShippingInfo
  if (shippingValue) {
    itemShippingInfo = (
      <Box className={styles.shippingValue}>
        <Typography variant={'boldText'}>
          {`${shippingValue.shippedAmount} / ${shippingValue.orderedAmount} ${shippingValue.unit}`}
        </Typography>
        <Typography variant={'smallText'} color={'text.secondary'}>
          Geliefert
        </Typography>
      </Box>
    )
  }

  return (
    <CardContent className={clsx(styles.root, { [styles.deadProduct]: deadProduct })}>
      {!deadProduct && !hideActionsElements && (
        <div className={styles.actions}>{actionsElements}</div>
      )}
      <div className={styles.header}>
        {leftAdornments}
        {!hideImage && <ProductImage size={imageSize}>{imageElement}</ProductImage>}
        {titlePartOfHeader && titleElement}
      </div>
      {topAdornments && <div className={styles.topAdornemnts}>{topAdornments}</div>}
      {!titlePartOfHeader && titleElement}
      {!deadProduct && (
        <div className={styles.body}>
          <div
            className={clsx(styles.properties, {
              [styles.extraSpacing]: deadProduct,
              [styles.shippingInfo]: shippingValue,
            })}
          >
            {propertiesElements}
          </div>
          <div className={clsx({ [styles.ubermeta]: statusesElement || labels })}>
            <div
              className={clsx(styles.meta, {
                [styles.shippingInfo]: shippingValue,
              })}
            >
              {statusesElement}
              {labels && (
                <>
                  <LightGrayDivider orientation="vertical" flexItem />
                  {labels}
                </>
              )}
            </div>
            {shippingValue && itemShippingInfo}{' '}
          </div>
          {shippingValue && <LightGrayDivider />}
        </div>
      )}
      {deadProduct && (
        <div className={styles.deadProductBody}>
          <div className={styles.propertiesElementsAligned}>{propertiesElements}</div>
          <div className={styles.sizeCounterDeadProduct}>{counter}</div>
        </div>
      )}
      {!deadProduct && <Box className={styles.values}>{values}</Box>}
      {!deadProduct && (counter || (bottomAdornments && bottomAdornments.length > 0)) ? (
        <Bottom direction="horizontal">
          {counter}
          {bottomAdornments}
        </Bottom>
      ) : null}
      {bottomExtension}
    </CardContent>
  )
}
