import { ChangeEventHandler, FC, ReactText, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { IWithCheckbox } from './withCheckbox'
import { ICardWithProduct } from './withProduct'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { Counter } from '../counter/Counter'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { Box, Typography } from '@mui/material'
import { Horizontal, Mobile } from './layouts'
import withCheckboxStyles from '../product-card/withCheckbox.module.scss'
import styles from './makeOrderItem.module.scss'
import stylesCheckbox from './withCheckbox.module.scss'
import { Bottom } from './layouts/Bottom'
import { AddItemsToCartButton } from '../add-items-to-cart-button/AddItemsToCartButton'
import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { Checkbox } from '../checkbox/Checkbox'
import { OrderItemForDetailsPage } from '@obeta/models/lib/schema-models/order-details'
import { OrderStateItem } from '../orders/OrderStateItem'
import { TabletSmall } from '../product-card/layouts/TabletSmall'
import clsx from 'clsx'
import makeItemStyles from './makeItem.module.scss'
import { IdsTransferProductButton } from '../ids/IdsTransferProductButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { useIsIdsTransferBackPossible } from '@obeta/data/lib/hooks/ids/useIsIdsTransferBackPossible'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'
import { AddCartContextEnum, addProductToCartGraphQL } from '@obeta/data/lib/actions'
import { trackClick } from '@obeta/utils/lib/tracking'
import { OrderItemForInArrearsPage } from '@obeta/models/lib/schema-models/order-in-arrears'

interface OrderItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onAddClicked'
      | 'onInfoClicked'
      | 'product'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'stocks'
      | 'layout'
      | 'settings'
    >,
    IWithCheckbox {
  changeProductAmount: (orderItemPosition: number | string, amount: number) => void
  orderItem: OrderItemForDetailsPage | OrderItemForInArrearsPage
  onAddClicked: (orderItem: OrderItemForDetailsPage | OrderItemForInArrearsPage) => void
  loadingPrices?: boolean
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onCounterValidation?: BaseCardCounterProps['onInputValidation']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
  isLoggedIn: boolean
  isSapPromotionalProduct: boolean
  mobile: boolean
  modifiedAmount?: number // Displays modified amount in favour of origin order item amount
  tablet: boolean
  tabletWide: boolean
  withCheckbox: boolean
  selected: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  carts: ShoppingCartV2[]
  selectedCart: ShoppingCartV2 | undefined
  shippingValue: { shippedAmount: number; orderedAmount: number; unit: string }
}

export interface OrderItemForPdfProps {
  isOrderItem?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemMinimumAmount?: number
  orderPdfSupplierData?: { type: string; value: ReactText }
  orderWithPrice?: boolean
  orderItemAmount?: number
  orderItemShippingAmount?: number
}
export const makeOrderItem = (
  Card: FC<IBaseProductCard & OrderItemForPdfProps>
): FC<OrderItemProps & OrderItemForPdfProps> => {
  return (props) => {
    const {
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      orderItem,
      onAddClicked,
      changeProductAmount,
      carts,
      selectedCart,
      loadingPrices,
      onProductImageClicked,
      onCounterValidation,
      selectedStore,
      isOrderItem,
      orderWithPrice,
      user,
      isLoggedIn,
      isSapPromotionalProduct,
      mobile,
      modifiedAmount,
      tablet,
      tabletWide,
      withCheckbox,
      selected,
      onChange,
      orderPdfSupplierData,
      orderItemAmount,
      shippingValue,
      ...restCardProps
    } = props
    const layoutType = 'horizontal'
    const dispatch = useDispatch()
    const { setAmountRoundUpNotification } = useProductCard()
    const { t } = useTranslation()
    const isLoadingPrices = Boolean(loadingPrices)
    const mobileOrTabletAll = mobile || tablet || tabletWide
    const { handleClick: showDropdownTemplates, ...dropdownTemplates } = usePopoverState()
    const { desktop } = useBreakpoints()
    const priceDimension = orderItem.product?.priceDimension
    const productPrice = orderItem.product?.prices
    const orderNetPrice = orderItem.netPrice
    const unit = orderItem.product?.unit
    const stock = orderItem.product?.stock
    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'TotalPrice',
        value: isSapPromotionalProduct ? 0 : orderItem.totalPrice,
        loading: isSapPromotionalProduct ? false : isLoadingPrices,
        unit: '', // we don`t want to display units for product in shopping cart
        hideOldPriceElement: true,
      },
    ]
    if (!mobile && !tablet && !isSapPromotionalProduct) {
      prices.push({
        type: 'PurchasePrice',
        value: orderNetPrice,
        loading: isLoadingPrices,
        unit: unit ?? '',
        hideOldPriceElement: true,
      })
    }

    if (orderItem.metalAddition && !isSapPromotionalProduct) {
      prices.push({
        type: 'MetalAdition',
        value: orderItem.metalAddition,
        loading: isLoadingPrices,
        unit: '',
        hideOldPriceElement: true,
      })
    }

    const [productAmount, setProductAmount] = useState(modifiedAmount ?? orderItem.orderAmount)

    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const mobileCounterAvailable = Boolean(mobileOrTabletAll)
    const showIdsTransferBackButton = useIsIdsTransferBackPossible()
    useEffect(() => {
      if (orderItem.orderAmount !== productAmount) {
        modifiedAmount ? setProductAmount(modifiedAmount) : setProductAmount(orderItem.orderAmount)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderItem.orderAmount, modifiedAmount])

    const minimumAmount = orderItem.product?.minimumAmount

    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable: orderItem.product?.isCurrentlyNotAvailable ?? false,
      stocks: stock ?? [],
      user,
    })

    const leftAdornments =
      withCheckbox && !isSapPromotionalProduct
        ? [
            <Checkbox
              key={orderItem.id}
              checked={selected}
              className={stylesCheckbox.checkbox}
              onChange={onChange}
            />,
          ]
        : null

    const actions: JSX.Element[] = []

    const topAdornments: JSX.Element[] = []

    if (isSapPromotionalProduct) {
      topAdornments.push(
        <div className={styles.darkGrayFlag}>
          <Typography variant="smallText">{t('PROMOTIONS.FREE_GIVEAWAY')}</Typography>
        </div>
      )
    }

    // Display orderItemState (top-seller and tecSelect info not required!)
    topAdornments.push(
      <OrderStateItem key={orderItem.state.type} itemState={orderItem.state} hideCount />
    )
    if (orderItem.deliverySlipId) {
      topAdornments.push(
        <div className={styles.lightGrayFlag}>
          <Typography variant="smallTextBold">
            {t('ORDERS.SHIPPING_DETAILS.DELIVERY')} {orderItem.deliverySlipId}
          </Typography>
        </div>
      )
    }
    const orderItemTitle =
      !orderItem.product || orderItem.product.type === ProductType.custom
        ? orderItem.articleDescription
        : orderItem.product.title

    const onAddToCart = useCallback(
      (cart: ShoppingCartV2, amount?: number) => {
        trackClick('order-details-add-to-cart', {
          orderItemId: orderItem.id,
          cartId: cart.id,
          amount,
        })

        if (amount && orderItem.product) {
          dispatch(
            addProductToCartGraphQL({
              cartId: cart.id,
              items: [
                {
                  sapId: orderItem.product.sapId,
                  amount,
                  title: orderItem.product.title,
                },
              ],
              context: AddCartContextEnum.Generic,
            })
          )
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [orderItem]
    )

    const onCounterSubmit = useCallback(
      (amount: number) => {
        if (selectedCart) {
          onAddToCart(selectedCart, amount)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedCart]
    )

    /**
     * Update amount by number or SetStateAction.
     * @param value Number | SetStateAction
     */
    const updateAmount = (value: number | ((prev: number) => number)) => {
      if (typeof value === 'number') {
        //TODO: replace orderAmount with correct amount
        if (modifiedAmount || value !== orderItem.orderAmount) {
          setProductAmount(value)
          changeProductAmount(orderItem.id, value)
        }
      } else {
        const funcValue = value(productAmount)
        //TODO: replace orderAmount with correct amount
        if (modifiedAmount || funcValue !== orderItem.orderAmount) {
          setProductAmount(funcValue)
          changeProductAmount(orderItem.id, funcValue)
        }
      }
    }

    let propertiesForDisplay
    if (orderItem.product && !isSapPromotionalProduct) {
      propertiesForDisplay = [
        {
          type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
          value: orderItem.product?.dehaId,
        },
      ]
    }
    if (!isSapPromotionalProduct) {
      propertiesForDisplay.push({
        type: t<string>('ARTICLE_DETAIL.SUPPLIER_ARTICLE_NUMBER'),
        value: orderItem.supplierArticleId2,
      })
    }
    if (orderItem.supplierFilterName && !isSapPromotionalProduct) {
      propertiesForDisplay.push({
        type: t<string>('ORDERS.SUPPLIER'),
        value: orderItem.supplierFilterName,
      })
    }

    const bottomExtension = (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!desktop && showIdsTransferBackButton && (
          <div className={clsx(makeItemStyles.subActions, tablet && makeItemStyles.compact)}>
            {showIdsTransferBackButton && orderItem.product && (
              <div className={makeItemStyles.transferIdsButton}>
                <IdsTransferProductButton sapId={orderItem.product?.sapId} amount={productAmount} />
              </div>
            )}
          </div>
        )}
      </>
    )
    return (
      <div
        className={
          isSapPromotionalProduct && (desktop || tabletWide)
            ? styles.sapPromotionalProductCardWrapper
            : ''
        }
      >
        <Card
          onDeleteClicked={null}
          {...restCardProps}
          storeAddress={selectedStore?.address.name1 ?? ''}
          authenticated={isLoggedIn}
          title={orderItemTitle}
          properties={isSapPromotionalProduct ? [] : propertiesForDisplay}
          isSendable={(isSapPromotionalProduct || orderItem.product?.isSendable) ?? true}
          isCutProduct={(orderItem.product?.isCutProduct && !isSapPromotionalProduct) ?? false}
          productImage={{
            componentType: 'card',
            src: orderItem.product?.imageData?.images[0]?.large ?? '',
            alt: orderItemTitle,
            supplierImage:
              orderItem.product?.type === 'custom'
                ? orderItem.supplierImageData.large
                : orderItem.product?.supplierImageData?.large,
            supplierId:
              !orderItem.product || orderItem.product.type === 'custom'
                ? orderItem.supplierImageData.sapId
                : orderItem.product?.supplierId,
            oxomiId: orderItem.product?.oxomiId ?? '',
            className: '',
            onClick: onProductImageClicked,
          }}
          productType={orderItem.product?.type as ProductType}
          showStockData={!isSapPromotionalProduct}
          stocks={stocksMap}
          onAddClicked={
            (orderItem.product?.type as ProductType) !== ProductType.custom
              ? (e) => {
                  onAddClicked(orderItem)
                  showDropdownTemplates(e)
                }
              : null
          }
          onInfoClicked={null}
          showIdsTransferButton
          productAmount={productAmount}
          counter={
            !isLoggedIn || isSapPromotionalProduct ? null : (
              <Box sx={{}}>
                <Counter
                  stretchHorizontal={true}
                  amount={productAmount}
                  minimumAmount={minimumAmount || 0}
                  initialAmount={null}
                  variant={mobileOrTabletAll ? 'big' : 'small'}
                  readonly={mobileCounterAvailable}
                  disabled={false}
                  onInputValidation={(valid, amountToUse, amount) => {
                    if (!valid && orderItem.product) {
                      setAmountRoundUpNotification(
                        orderItem.product?.sapId,
                        amountToUse,
                        amount,
                        orderItem.product?.unit
                      )
                    }
                  }}
                  changeProductAmount={updateAmount}
                  onSubmit={onCounterSubmit}
                  onTextFieldClicked={() => {
                    setShowMobileCounter(true)
                  }}
                />
                {mobileCounterAvailable && (
                  <PopoverMobileCounter
                    open={showMobileCounter}
                    onClose={function () {
                      setShowMobileCounter(false)
                    }}
                    unit={orderItem.product?.unit ?? ''}
                    initialAmount={productAmount}
                    minimumAmount={minimumAmount || 0}
                    maxAcceptableAmount={9999}
                    onAccept={function (value: number): void {
                      updateAmount(value)
                      setShowMobileCounter(false)
                    }}
                  />
                )}
              </Box>
            )
          }
          isOrderItem={true}
          settings={{
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            labelDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          titleLines={mobile ? 6 : 2}
          layout={(layoutProps) => {
            const bottomAdornments =
              layoutProps.authenticated && orderItem.product
                ? [
                    <AddItemsToCartButton
                      key="add-items-to-cart-button"
                      carts={carts}
                      disableStandardAdd
                      selectedCart={selectedCart}
                      mobile={mobile}
                      product={orderItem.product}
                      productAmount={productAmount}
                      size={mobileOrTabletAll ? 'large' : 'small'}
                      algoliaUserToken={user?.algoliaUserToken}
                      disabled={orderItem.product.type === ProductType.custom}
                      updateCart={onAddToCart}
                    />,
                  ]
                : null

            const renderPricesBlock = createRenderPricesBlock(
              {
                prices,
                pricePermissions: user?.permissions?.Global_canReadPrices,
                currency: productPrice?.currency || '',
                priceDimension,
              },
              layoutType,
              isLoggedIn,
              false,
              false,
              orderWithPrice,
              true
            )
            const actionsElements = layoutProps.actionsElements.concat(actions)
            if (mobile) {
              return (
                <>
                  <Mobile
                    {...layoutProps}
                    leftAdornments={isSapPromotionalProduct ? null : leftAdornments}
                    topAdornments={topAdornments}
                    bottomAdornments={isSapPromotionalProduct ? null : bottomAdornments}
                    actionsElements={isSapPromotionalProduct ? [] : actionsElements}
                    imageSize={
                      layoutProps.authenticated
                        ? IMAGE_SIZE_AUTHENTICATED
                        : IMAGE_SIZE_UNAUTHENTICATED
                    }
                    values={renderPricesBlock()}
                    shippingValue={shippingValue}
                    bottomExtension={bottomExtension}
                  />
                  {orderItem.product &&
                    (orderItem.product.type as ProductType) !== ProductType.custom &&
                    !isSapPromotionalProduct && (
                      <DropdownTemplatesBase
                        dropdown={dropdownTemplates}
                        mobile={mobile}
                        productsToAdd={[
                          { productId: orderItem.product?.sapId, amount: productAmount },
                        ]}
                        templatesType={DropdownTemplatesType.ADD_ORDER_ITEMS}
                      />
                    )}
                </>
              )
            }

            if (tablet) {
              const { counter, ...restLayoutProps } = layoutProps
              return (
                <>
                  <TabletSmall
                    isOrderItem={true}
                    {...restLayoutProps}
                    className={withCheckboxStyles.tablet}
                    counter={null}
                    leftAdornments={isSapPromotionalProduct ? null : leftAdornments}
                    topAdornments={topAdornments}
                    bottomAdornments={null}
                    bottomRightAdornments={
                      !isSapPromotionalProduct && [
                        <Bottom key="bottom-adornments" direction="vertical">
                          {counter}
                          {bottomAdornments}
                        </Bottom>,
                      ]
                    }
                    mobile={mobile}
                    actionsElements={isSapPromotionalProduct ? [] : actionsElements}
                    mapValues={renderPricesBlock}
                    orderItemAmount={orderItem.orderAmount}
                    orderItemShippingAmount={orderItem.shippingAmount ?? 0}
                    orderItemUnit={orderItem.unit ?? ''}
                    shippingValue={shippingValue}
                    bottomExtension={bottomExtension}
                  />
                  {orderItem.product &&
                    (orderItem.product.type as ProductType) !== ProductType.custom &&
                    !isSapPromotionalProduct && (
                      <DropdownTemplatesBase
                        dropdown={dropdownTemplates}
                        mobile={mobile}
                        productsToAdd={[
                          { productId: orderItem.product?.sapId, amount: productAmount },
                        ]}
                        templatesType={DropdownTemplatesType.ADD_ARTICLES}
                      />
                    )}
                </>
              )
            }

            return (
              <>
                <Horizontal
                  isOrderItem={true}
                  orderItemUnit={orderItem.unit ?? ''}
                  {...layoutProps}
                  leftAdornments={isSapPromotionalProduct ? null : leftAdornments}
                  topAdornments={topAdornments}
                  bottomRightAdornments={isSapPromotionalProduct ? null : bottomAdornments}
                  bottomAdornments={null}
                  labelsInAdditionalValues={false}
                  mobile={mobile}
                  actionsElements={isSapPromotionalProduct ? [] : actionsElements}
                  mapValues={renderPricesBlock}
                  shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                  shoppingCartItemAmount={shoppingCartItemAmount}
                  orderPdfSupplierData={orderPdfSupplierData}
                  orderWithPrice={orderWithPrice}
                  orderItemAmount={orderItem.orderAmount}
                  orderItemShippingAmount={orderItem.shippingAmount ?? 0}
                  shippingValue={shippingValue}
                />
                {orderItem.product &&
                  (orderItem.product.type as ProductType) !== ProductType.custom &&
                  !isSapPromotionalProduct && (
                    <DropdownTemplatesBase
                      dropdown={dropdownTemplates}
                      mobile={mobile}
                      productsToAdd={[
                        { productId: orderItem.product.sapId, amount: productAmount },
                      ]}
                      templatesType={DropdownTemplatesType.ADD_ARTICLES}
                    />
                  )}
              </>
            )
          }}
          {...restCardProps}
        />
      </div>
    )
  }
}
